<template>
    <div class="wrap">
        <b-container class="commodity-group">
            <div class="login-group-tab">
                <span @click="getType(1)" :class="showFlag==1?'currs':''">{{$t('message.emailfind')}}</span>
                <span @click="getType(2)" :class="showFlag==2?'currs':''">{{$t('message.phonefind')}}</span>
            </div>
            <div class="login-group" v-show="showFlag==1"><input type="text" v-model="email" class="password" :placeholder="$t('message.emailnum')"/></div>
            <div class="login-group" v-show="showFlag==2">
                <select name="" id="" v-model="couponSelected">
                    <option :value="item.mobile_area_code" v-for="(item,index) in languageList" :key="index">{{item.mobile_area_code}}+</option>
                </select>
                <input type="text" class="" v-model="phone" :placeholder="$t('message.phonenum')"/>
            </div>
            <div class="login-group-code" >
                <input type="text" class="" v-model="code" :placeholder="$t('message.ycode')"/>
                <div class="button" :class="dislebal?'glay':''">
                    <span v-show="dislebal">{{count}}s{{$t('message.coderest')}}</span>
                    <span v-show="!dislebal" @click="getCode">{{$t('message.getcode')}}</span>
                </div>
            </div>
            <div class="login-group"><input type="password" v-model="password" class="password" :placeholder="$t('message.passnum')"/></div>
            <div class="login-group-btn" @click="getFindPassWord">{{$t('message.nowfind')}}</div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,yooz_lang,web_inter,delLocalItem} from '@/untils/common';
export default {
    name:'passfind',
    inject:['reload'],
    data() {
        return {
            timer: null,
            showFlag:1,
            phone:'',
            code:'',
            dislebal:false,
            count:0,
            email:'',
            password:'',
            // languageCodeObj:{},
            languageObj:{},
            languageList:web_inter.languageList,
            couponSelected:''
        }
    },
    computed:{
        
    },
    watch:{
       
    },
    methods: {
        
        // 电子邮箱和手机号切换
        getType(type){
            this.showFlag = type;
            this.phone = '';
            this.code = '';
            this.dislebal = false;
            this.count = 0;
            this.email = '';
            this.password = '';
        },
        // 获取验证码
        getCode(){
            let self = this;
            // 邮箱
            if(self.showFlag==1){
                if (this.email=='') {
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.email.test(this.email)){
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    let params = {
                        email:this.email,//（邮箱）
                        type:7,//（类型：  6邮箱注册 7邮箱忘记密码）
                    }
                    this.$http.api_email_sendcode(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    ElementUI.Message({
                                        message:this.languageObj.codesuccess,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                    self.dislebal = true;
                                    const TIME_COUNT = 60;
                                    if (!self.timer) {
                                        self.count = TIME_COUNT;
                                        self.timer = setInterval(() => {
                                            if (self.count > 0 && self.count <= TIME_COUNT) {
                                                self.count--;
                                            } else {
                                                clearInterval(self.timer);
                                                self.timer = null;
                                                self.dislebal = false;
                                            }
                                        }, 1000)
                                    }
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else{
                // 手机号
                if (this.phone=='') {
                    ElementUI.Message({
                        message:this.languageObj.phonetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.number.test(this.phone)){
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    let params = {
                        telno:this.phone,//（手机号）、 
                        type:2,//（类型： 1短信注册 2短信忘记密码）,
                        areacode:this.couponSelected,//String(this.languageCodeObj.mobile_area_code)
                    }
                    this.$http.api_phone_sendcode(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                self.dislebal = true;
                                const TIME_COUNT = 60;
                                if (!self.timer) {
                                    self.count = TIME_COUNT;
                                    self.timer = setInterval(() => {
                                        if (self.count > 0 && self.count <= TIME_COUNT) {
                                            self.count--;
                                        } else {
                                            clearInterval(self.timer);
                                            self.timer = null;
                                            self.dislebal = false;
                                        }
                                    }, 1000)
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }
        },
        // 提交立即找回
        getFindPassWord(){
            if(this.showFlag==1){
                if (this.email=='') {
                    ElementUI.Message({
                        message:this.languageObj.emailtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.email.test(this.email)){
                    ElementUI.Message({
                        message:this.languageObj.emailrighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                } else if(this.code==''){
                    ElementUI.Message({
                        message:this.languageObj.emailcodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    this.$http.api_email_forgetpass({
                        account:this.email,
                        code:this.code,
                        password:this.password,
                    }).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    ElementUI.Message({
                                        message:this.languageObj.pwdsuccess,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                    setTimeout(()=>{
                                        this.$store.commit('setLoginNameStatus', {});
                                        delLocalItem('login_user_info');
                                        this.$root.Bus.$emit('userInfoClick', {});
                                        this.$store.commit('setLoginStatus',true);
                                        this.$router.push({path:'/'});
                                    },2000);
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else{
                if (this.phone=='') {
                    ElementUI.Message({
                        message:this.languageObj.phonetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false
                }else if(!jsReg.number.test(this.phone)){
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.code==''){
                    ElementUI.Message({
                        message:this.languageObj.emailcodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.password==''){
                    ElementUI.Message({
                        message:this.languageObj.passwordtip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    this.$http.api_phone_forgetpass({
                        account:this.phone,
                        code:this.code,
                        password:this.password,
                        areacode:this.couponSelected,//String(this.languageCodeObj.mobile_area_code)
                    }).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    ElementUI.Message({
                                        message:this.languageObj.pwdsuccess,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                    setTimeout(()=>{
                                        this.$store.commit('setLoginNameStatus', {});
                                        delLocalItem('login_user_info');
                                        this.$store.commit('setLoginStatus',true);
                                        this.$router.push({path:'/'});
                                    },2000);
                                }
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }
        }
    },
    mounted(){
        let that = this;
        that.$nextTick(()=>{
            // that.languageCodeObj = that.$store.state.data.languageNameStatus;
            // that.languageList = that.$store.state.data.languageNameList&&this.$store.state.data.languageNameList.length>0?this.$store.state.data.languageNameList:(localStorage.getLanguageListName&&JSON.parse(localStorage.getLanguageListName).length>0?JSON.parse(localStorage.getLanguageListName):[])
            that.couponSelected = that.languageList[0].mobile_area_code
        });
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "emailtip":"请输入您的邮箱",
                "passwordtip":"请输入您的密码",
                "phonetip":"请输入您的手机号",
                "codesuccess":"验证码发送成功",
                "pwdsuccess":"修改密码成功",
                "emailcodetip":"请输入您的验证码",
                "emailrighttip":"请输入正确的邮箱",
                "phonerighttip":"请输入正确的手机号",

            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "emailtip":"enter your email",
                "passwordtip":"enter your password",
                "phonetip":"enter your mobile number",
                "codesuccess":"Verification code sent successfully",
                "pwdsuccess":"Password changed successfully",
                "emailcodetip":"Please enter your verification code",
                "emailrighttip":"Please enter the correct email address",
                "phonerighttip":"Please enter the correct mobile number",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "emailtip":"inserisci la tua email",
                "passwordtip":"inserisci la password",
                "phonetip":"inserisci il tuo numero di cellulare",
                "codesuccess":"Codice di verifica inviato correttamente",
                "pwdsuccess":"Password modificata correttamente",
                "emailcodetip":"Inserisci il tuo codice di verifica",
                "emailrighttip":"Inserisci l'indirizzo email corretto",
                "phonerighttip":"Inserisci il numero di cellulare corretto",
            }
        }
        let routes = this.$route.query;//地址栏参数
        if(routes){
            this.showFlag = routes.type;
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap{
    height:100%;
    width:100%;
}
.login-group-tab{
    padding:30px 0 10px;
    margin-bottom: 20px;
    text-align: center;
    span{
        cursor: pointer;
        display: inline-block;
        margin:0 20px;
        padding:5px 0;
        font-size:18px;
    }
    .currs{
        border-bottom: 1px solid #222;
    }
}
.login-group{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    padding:10px 0;
    margin-bottom: 20px;
    
    select,input{
        outline:none;
        border:none;
        padding:0 10px;
        background: transparent;
    }
    .password{
        width: 100%;
    }
}
.login-group-btn{
    cursor: pointer;
    background: #222;
    color: #fff;
    text-align: center;
    border-radius:4px;
    padding:10px 0;
    
}
.login-group-code{
    border: 1px solid #DDE0ED;
    border-radius:4px;
    margin-bottom: 20px;
    display: flex;
    -webkit-box-orient: horizontal;
    position: relative;
    input{
        width:51%;
        padding:10px 10px;
        outline:none;
        border:none;
        background: transparent;
    }
    .button{
        position: absolute;
        top: 0;
        right:0;
        // width: 30%;
        padding:10px;
        text-align: center;
        color: #fff;
        border-radius:4px;
        background: #1F73B7;
        cursor: pointer;
        span{
            color: #fff;
        }
    }
    .glay{
        background: #9a9a9a;
    }
}

.commodity-group{
    padding:30px 0 50px;
}
@media screen and (min-width: 1200px) {
    .wrap{
        height: auto;
        .login-group-tab{
            // width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group-btn{
            width:500px;
            margin: 0 auto;
        }
        .login-group-code{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        height: auto;
        .login-group-tab{
            // width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group-btn{
            width:500px;
            margin: 0 auto;
        }
        .login-group-code{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        height: auto;
        .login-group-tab{
            // width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
        .login-group-btn{
            width:500px;
            margin: 0 auto;
        }
        .login-group-code{
            width:500px;
            margin: 0 auto;
            margin-bottom: 20px;
        }
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        padding:0 60px;
        height: auto;
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        padding:0 20px;
        height: auto;
    }
}
</style>